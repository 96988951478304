import { QueryClient, useQuery, useQueryClient } from "@tanstack/react-query"
import { GetBackendClient } from "../../services/BackendClientFactory"
import { useAuthStateQuery } from "../token/TokenQuery"

export const oneTimeCommodityQueryKey = "oneTimeCommodities"

export const useOneTimeCommodityQuery = () => {
    const { msToken, allReady } = useAuthStateQuery()

    const { data, isError, isLoading } = useQuery({
        queryKey: [oneTimeCommodityQueryKey],
        queryFn: getOneTimeCommodities(msToken),
        enabled: allReady
    })

    const openPositions = data?.filter(c => !c.onInvoice && !c.settledUp) ?? []
    const onInvoicePositions = data?.filter(c => c.onInvoice && !c.settledUp) ?? []

    const totalOpen = openPositions.reduce((acc, c) => acc + c.costOfOne * c.amount, 0)
    const totalOnInvoice = onInvoicePositions.reduce((acc, c) => acc + c.costOfOne * c.amount, 0)

    return { oneTimeCommodities: data, isError, isLoading, totalOpen, totalOnInvoice }
}

export const prefetchOneTimeCommodities = async (msToken: string, queryClient: QueryClient) => {
    await queryClient.prefetchQuery({
        queryKey: [oneTimeCommodityQueryKey],
        queryFn: getOneTimeCommodities(msToken),
    })
}

const getOneTimeCommodities = (msToken: string | undefined) => async () => {
    const resp = await GetBackendClient(msToken).oneTimeCommodities.getOneTimeCommodities()
    return resp.data
}