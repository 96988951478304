import { FC } from "react";
import { useOneTimeCommodityQuery } from "../store/oneTimeCommodities/OneTimeCommodityQueries";
import { faCircleCheck, faFileInvoiceDollar, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Table, Spinner, Accordion } from "react-bootstrap";
import { OneTimeCommodityResource } from "../generated/BackendApiClient";
import { OneTimeCommoditiesCreationLine } from "./OneTimeCommoditiesCreationLine";
import { useDeleteOneTimeCommodity } from "../store/oneTimeCommodities/OneTimeCommodityMutations";
import { localStringFrom } from "../util/NumberUtil";
import { asLocalDate } from "../util/DateUtil";

type OneTimeCommoditiesListProps = {
    showSettled: boolean
}

export const OneTimeCommoditiesList: FC<OneTimeCommoditiesListProps> = ({ showSettled }) => {

    const { oneTimeCommodities, isError, isLoading, totalOnInvoice, totalOpen } = useOneTimeCommodityQuery()
    const deleteOneTimeCommodity = useDeleteOneTimeCommodity()

    const fresh = oneTimeCommodities?.filter(c => !c.onInvoice && !c.settledUp).sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime()) ?? []
    const onInvoice = oneTimeCommodities?.filter(c => c.onInvoice && !c.settledUp) ?? []
    const settledUp = oneTimeCommodities?.filter(c => c.onInvoice && c.settledUp) ?? []

    const onDeleteClicked = (commodity: OneTimeCommodityResource) => async () => {
        if (!window.confirm(`Willst du wirklich '${commodity.name}' löschen?`)) return

        await deleteOneTimeCommodity.mutateAsync(commodity.id)
    }

    return (
        <>
            <div className="md:hidden block w-full">
                {isLoading && <Spinner />}
                <Accordion className="w-full">
                    {fresh?.map(c =>
                        <Accordion.Item key={c.id} eventKey={c.id}>
                            <Accordion.Header>{c.name} - {c.receivingCustomer.name}</Accordion.Header>
                            <Accordion.Body>
                                <Table>
                                    <tbody>
                                        <tr><td>Kunde:</td><td>{c.receivingCustomer.name}</td></tr>
                                        <tr><td>Anzahl:</td><td>{c.amount}{c.unit}</td></tr>
                                        <tr><td>Einzelpreis:</td><td>{localStringFrom(c.costOfOne)}€</td></tr>
                                        <tr><td>Datum:</td><td>{asLocalDate(c.date)}</td></tr>
                                        <tr><td>Beschreibung:</td><td>{c.description}</td></tr>
                                    </tbody>
                                </Table>
                            </Accordion.Body>
                        </Accordion.Item>

                    )}
                    {onInvoice?.map(c =>
                        <Accordion.Item key={c.id} eventKey={c.id}>
                            <Accordion.Header><FontAwesomeIcon icon={faFileInvoiceDollar} className="pr-2" />{c.name} - {c.receivingCustomer.name}</Accordion.Header>
                            <Accordion.Body>
                                <Table>
                                    <tbody>
                                        <tr><td>Kunde:</td><td>{c.receivingCustomer.name}</td></tr>
                                        <tr><td>Anzahl:</td><td>{c.amount}{c.unit}</td></tr>
                                        <tr><td>Einzelpreis:</td><td>{localStringFrom(c.costOfOne)}€</td></tr>
                                        <tr><td>Datum:</td><td>{asLocalDate(c.date)}</td></tr>
                                        <tr><td>Beschreibung:</td><td>{c.description}</td></tr>
                                    </tbody>
                                </Table>
                            </Accordion.Body>
                        </Accordion.Item>

                    )}
                    {showSettled && settledUp?.map(c =>
                        <Accordion.Item key={c.id} eventKey={c.id}>
                            <Accordion.Header><FontAwesomeIcon icon={faCircleCheck} className="pr-2" />{c.name} - {c.receivingCustomer.name}</Accordion.Header>
                            <Accordion.Body>
                                <Table>
                                    <tbody>
                                        <tr><td>Kunde:</td><td>{c.receivingCustomer.name}</td></tr>
                                        <tr><td>Anzahl:</td><td>{c.amount}{c.unit}</td></tr>
                                        <tr><td>Einzelpreis:</td><td>{localStringFrom(c.costOfOne)}€</td></tr>
                                        <tr><td>Datum:</td><td>{asLocalDate(c.date)}</td></tr>
                                        <tr><td>Beschreibung:</td><td>{c.description}</td></tr>
                                    </tbody>
                                </Table>
                            </Accordion.Body>
                        </Accordion.Item>

                    )}
                </Accordion>
            </div>
            <div className="hidden md:flex md:flex-col md:items-center w-full">
                <Table striped>
                    <thead>
                        <tr>
                            <th></th>
                            <th>Name</th>
                            <th>Kunde</th>
                            <th className="text-right">#</th>
                            <th></th>
                            <th>Einzelpreis</th>
                            <th>Datum</th>
                            <th>Beschreibung</th>
                        </tr>
                    </thead>
                    <tbody>
                        {fresh?.map(c =>
                            <tr key={c.id}>
                                <td></td>
                                <td>{c.name}</td>
                                <td>{c.receivingCustomer.name}</td>
                                <td className="text-right">{localStringFrom(c.amount)}</td>
                                <td>{c.unit}</td>
                                <td>{localStringFrom(c.costOfOne)}€</td>
                                <td>{asLocalDate(c.date)}</td>
                                <td>{c.description}</td>
                                <td><FontAwesomeIcon className="cursor-pointer text-red-400" icon={faTrash} onClick={onDeleteClicked(c)} /></td>
                            </tr>
                        )}
                        {onInvoice?.map(c =>
                            <tr key={c.id}>
                                <td><FontAwesomeIcon icon={faFileInvoiceDollar} /></td>
                                <td>{c.name}</td>
                                <td>{c.receivingCustomer.name}</td>
                                <td className="text-right">{localStringFrom(c.amount)}</td>
                                <td>{c.unit}</td>
                                <td>{localStringFrom(c.costOfOne)}€</td>
                                <td>{asLocalDate(c.date)}</td>
                                <td>{c.description}</td>
                                <td></td>
                            </tr>
                        )}
                        {showSettled && settledUp?.map(c =>
                            <tr key={c.id}>
                                <td><FontAwesomeIcon icon={faCircleCheck} /></td>
                                <td>{c.name}</td>
                                <td>{c.receivingCustomer.name}</td>
                                <td className="text-right">{localStringFrom(c.amount)}</td>
                                <td>{c.unit}</td>
                                <td>{localStringFrom(c.costOfOne)}€</td>
                                <td>{asLocalDate(c.date)}</td>
                                <td>{c.description}</td>
                                <td></td>
                            </tr>
                        )}
                        <OneTimeCommoditiesCreationLine />
                        {(oneTimeCommodities?.length ?? 0) === 0 && !isError && !isLoading &&
                            <tr>
                                <td colSpan={9}>Noch keine Dienstleistungen angelegt...</td>
                            </tr>
                        }
                        {isError &&
                            <tr>
                                <td colSpan={9}>Es gab einen fehler beim Laden der Dienstleistungen...</td>
                            </tr>
                        }
                        {isLoading &&
                            <tr>
                                <td colSpan={9}><Spinner /></td>
                            </tr>
                        }
                    </tbody>
                </Table>
                <div className="flex justify-end w-full">
                    <div className="flex flex-col items-end" style={{ fontWeight: 'bold' }}>
                        <div className="flex">
                            <div className="pr-2">Offen:</div>
                            <div>{localStringFrom(totalOpen)}€</div>
                        </div>
                        <div className="flex">
                            <div className="pr-2">Auf Rechnung:</div>
                            <div>{localStringFrom(totalOnInvoice)}€</div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}