import { FC, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { jsPDF } from 'jspdf'
import { FullInvoice } from '../components/invoice/FullInvoice';
import '../components/invoice/invoice-pdf.css';
import { Button, Spinner } from 'react-bootstrap';
import { useInvoiceByIdQuery } from '../store/invoices/InvoiceQueries';
import { InvoiceResource, SendFileAsMailResource } from '../generated/BackendApiClient';
import { localStringFrom } from '../util/NumberUtil';
import { logoImageData } from '../components/invoice/LogoImageData';
import { GetBackendClient } from '../services/BackendClientFactory';
import { useAuthStateQuery } from '../store/token/TokenQuery';
import { useDeleteInvoiceMutation, usePublishInvoiceMutation, useSettleUpInvoiceMutation } from '../store/invoices/InvoiceMutations';

export const InvoicePreview: FC = () => {

    const logoImage = logoImageData

    const { invoiceId } = useParams();
    const { invoice, isLoading: invoiceLoading } = useInvoiceByIdQuery(invoiceId)
    const publishMutation = usePublishInvoiceMutation()
    const settleUpMutation = useSettleUpInvoiceMutation()
    const deleteMutation = useDeleteInvoiceMutation()
    const { msToken, allReady } = useAuthStateQuery()
    const nav = useNavigate()

    const [loading, setLoading] = useState(false);

    if (invoiceLoading || invoice === undefined || !allReady) {
        return <div>Loading...</div>;
    }

    const pdfId = "pdf-" + Math.round(Math.random() * 1000);

    const generateFileName = (invoice: InvoiceResource) => generateInvoiceName(invoice) + ".pdf";

    const generateInvoiceName = (invoice: InvoiceResource) => {
        const date = new Date(invoice.invoiceDate);
        const euro = localStringFrom(invoice.totalCost) + "EUR";
        return `Rechnung-BlackForestSentinel-${date.getFullYear()}-${date.getMonth() + 1}--${euro}`;
    }

    const generatePDF = (): Promise<jsPDF> => {
        const pdfPromise = new Promise<jsPDF>((resolve, reject) => {

            const element = document.getElementById(pdfId);
            if (element === null) {
                console.error('pdf not found', pdfId)
                return;
            }

            const pdf = new jsPDF();
            pdf.html(element, {
                callback: doc => {
                    const pages = doc.getNumberOfPages()

                    for (let i = 1; i <= pages; i++) {
                        doc.deletePage(2);
                    }

                    resolve(doc)
                },
                margin: [0, 0, 0, 0],
                autoPaging: 'text',
                x: 0,
                y: 0,
                width: 210, //target width in the PDF document
                windowWidth: 790 //window width in CSS pixels
            });
        });

        return pdfPromise
    }

    const download = async () => {
        const pdf = await generatePDF()
        await pdf.save(generateFileName(invoice), { returnPromise: true })
    }

    const publish = async () => {
        await publishMutation.mutateAsync(invoice.id)
    }

    const deleteInvoice = async () => {
        await deleteMutation.mutateAsync(invoice.id)
        nav('/')
    }

    const sendPDF = async () => {

        const fetchInvoice = async (pdf: jsPDF) => {
            setLoading(true);

            const base64Data = pdf.output('dataurlstring').split(',')[1]

            const data: SendFileAsMailResource = {
                base64Data,
                fileName: generateFileName(invoice),
                htmlMessage: document.getElementById('mail-content-div')!.innerHTML,
                subject: generateInvoiceName(invoice),
                toAddress: invoice.customer.email,
                invoice: invoice
            }

            await GetBackendClient(msToken).mail.sendMail(data)
                .then(response => response.data)
                .then(sendMailresult => alert(sendMailresult.message))
                .then(() => setLoading(false));
        };

        const pdf = await generatePDF()
        await fetchInvoice(pdf)
    }

    const markAsPayed = async () => {
        await settleUpMutation.mutateAsync(invoice.id)
    }

    return (
        <>
            <div className="md:hidden flex flex-col items-center w-full">
                <h2 className="text-lg md:text-s">{generateFileName(invoice)}</h2>
                <div className="w-full overflow-x-auto">
                    <div className='scale-[.4] my-[-300px] mx-[-200px]' style={{ border: 'solid black 1px', height: '1120px', width: '790px' }}>
                        <div className='a4-page' id={pdfId}>
                            <div className='content'>
                                <FullInvoice invoice={invoice} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="hidden md:grid" style={{ height: '100%' }}>
                {invoice.isSettled ? <h3>Diese rechnung ist bereits bezahlt</h3> : <></>}
                {!invoice.isSettled && invoice.isPublished ? <Button style={{ width: 400, height: 50, marginTop: 20 }} onClick={markAsPayed}>Als bezahlt markieren</Button> : <></>}
                <div style={{ marginTop: '30px' }}>
                    <h2>{generateFileName(invoice)}</h2>
                    <div style={{ border: 'solid black 1px', height: '1120px', width: '790px' }}>
                        <div className='a4-page' id={pdfId}>
                            <div className='content'>
                                <FullInvoice invoice={invoice} />
                            </div>
                        </div>
                    </div>
                    {invoice.isPublished && <button style={{ width: 400, height: 50, marginTop: 20 }} className='btn btn-primary' onClick={download}>Download</button>}
                    {!invoice.isPublished && <button style={{ width: 400, height: 50, marginTop: 20, marginLeft: 20 }} className='btn btn-primary' onClick={publish}>Veröffentlichen</button>}
                    {!invoice.isPublished && <button style={{ width: 400, height: 50, marginTop: 20, marginLeft: 20 }} className='btn btn-primary' onClick={deleteInvoice}>Löschen</button>}
                </div>
                <div style={{ marginTop: '30px' }}>
                    <div id='mail-content-div' style={{ border: 'solid black 1px', padding: '20px' }}>
                        <div>
                            Sehr geehrte Damen und Herren ({invoice.customer.name}),<br />
                            <br />
                            mit dieser Mail erhalten Sie unsere <b>Rechnung Nr.
                                {invoice.invoiceNumber} über {localStringFrom(invoice.totalCost)} EUR</b>.<br />
                            Sollten Sie Fragen bezüglich der Rechnung haben, stehen wir Ihnen jederzeit gerne zur Verfügung.<br />
                            <br />
                            Wir bedanken uns für Ihren Auftrag und freuen uns auf die weitere Zusammenarbeit.<br />
                            <br />
                            <br />
                            Mit freundlichen Grüßen<br />
                            ihr <b>BlackForestSentinel Team</b><br />
                            <br />
                            <div>
                                <a style={{ color: '#46627b', fontSize: 'small' }} href="mailto:contact@blackforestsentinel.de">contact@blackforestsentinel.de</a><br />
                                <a style={{ color: '#46627b', fontSize: 'small' }} href="https://blackforestsentinel.de">blackforestsentinel.de</a><br />
                                <br />
                                <img width={100} height={100} src={logoImage} alt="Sentinel Logo" /><br />
                                <small style={{ color: 'gray', fontSize: 'x-small' }}>Diese Mail wurde automatisch generiert durch die Sentinel-Billing Schnittstelle.</small>
                            </div>
                        </div>
                    </div>
                    <br />
                    <div style={{ display: 'flex' }}>
                        {!invoice.isPublished && <p>Nur veröffentlichte Rechnungen können versendet werden.</p>}
                        {invoice.isPublished && <button disabled={invoice.isSettled} style={{ marginRight: 20, width: 400, height: 50 }} className='btn btn-primary' onClick={sendPDF}>Send to {invoice.customer.email}</button>}
                        {loading && (
                            <Spinner />
                        )}
                    </div>
                </div>
            </div>
        </>
    );
}